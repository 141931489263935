import { createRouter, createWebHistory } from 'vue-router';
import { routes } from 'vue-router/auto-routes';

import { getToken } from '@/services/api/admin-auth';

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  if (to.path.startsWith('/admin') && to.path !== '/admin/login') {
    // Ensure that the user is authenticated before accessing the admin panel.
    // For efficiency, `getToken` is only called if the user tries to access
    // the admin route.
    if (!(await getToken())) {
      return '/admin/login';
    }
  }

  // If the user is already authenticated, prevent them from accessing the login screen.
  // Attempting to sign in again after already being signed in will result in
  // `UserAlreadyAuthenticated` exception and we don't want that.
  if (to.path === '/admin/login') {
    if (await getToken()) {
      return '/admin/applicants';
    }
  }
});

// Backwards compatibility: currently `/admin` will try to redirect to `/admin/applicants`.
routes.push({ path: '/admin', redirect: '/admin/applicants' });
