import {
  fetchAuthSession as amplifyFetchAuthSession,
  getCurrentUser as amplifyGetCurrentUser,
  signInWithRedirect as amplifySignInWithRedirect,
  signOut as amplifySignOut,
} from 'aws-amplify/auth';

async function signIn() {
  return amplifySignInWithRedirect({ provider: 'Google' });
}

async function signOut() {
  return amplifySignOut();
}

/**
 * Attempts to get the current user's token which can be used to access
 * Salento's internal API which is hosted in AWS API Gateway.
 *
 * {@link https://github.com/aws-amplify/amplify-js/blob/de6b06db80b02687e597aceaa2c33f65171a6389/packages/core/src/singleton/Auth/index.ts#L46-L95}
 * {@link https://docs.amplify.aws/vue/build-a-backend/auth/concepts/tokens-and-credentials/}
 */
async function getToken() {
  try {
    const session = await amplifyFetchAuthSession();
    if (!session.tokens?.idToken) {
      return null;
    }

    return session.tokens.idToken.toString();
  } catch {
    // If the current user does not have a token / fails to get the token somehow,
    // then it means that they are not authenticated. It could be derived from this state.
    return null;
  }
}

/**
 * Fetch the current user from the AWS Amplify. This function will
 * throw if the user is not authenticated.
 */
async function getCurrentUser() {
  return amplifyGetCurrentUser();
}

export { getCurrentUser, getToken, signIn, signOut };
