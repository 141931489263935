import { type RouteLocationNormalizedLoaded } from 'vue-router';

export const upperCaseFirstLetter = (s: string) => {
  return `${s.charAt(0).toUpperCase()}${s.slice(1)}`;
};

export const eachWordUppercase = (s: string) => {
  return (s || '').split('-').map(upperCaseFirstLetter).join(' ');
};

export const hasEntryCodeInQueryParameter = (
  route: RouteLocationNormalizedLoaded,
) => {
  const entryCode = route.query['entry-code'];
  if (entryCode && !Array.isArray(entryCode)) {
    return { existence: true, value: entryCode } as const;
  }

  return { existence: false } as const;
};

export const isViteProduction = import.meta.env.MODE === 'production';

export const VERSION = import.meta.env.VITE_APP_VERSION || 'unknown';

export const setupEnvironmentFromUrl = () => {
  const origin = window.location.origin;
  const gtagId = isViteProduction ? 'UA-132534627-3' : 'UA-132534627-4';
  const gtmId = 'GTM-WR3SMF';
  const dsn =
    'https://e3a058cdaefe480d897c5bac0d73b040@o26148.ingest.us.sentry.io/1891111';

  switch (origin) {
    case 'https://challenge.hennge.com':
      return {
        stage: 'prd',
        dsn,
        gtmId,
        gtagId,
        apiEndpoint: 'https://api.challenge.hennge.com',
        recaptchaSiteKey: '6LdlH60UAAAAAJTjwBD7wTB_FFiAeH_9xzMnBh8E',
        authClientId: '7o0kprpfmh5qdd325pom2r2f92',
        authWebDomain: 'auth.challenge.hennge.com',
        authUserPoolId: 'ap-northeast-1_E1kBGocrN',
        webBaseUrl: 'https://challenge.hennge.com',
      };

    case 'https://salento.prd.demodesu.com':
      return {
        stage: 'stg2',
        dsn,
        gtmId,
        gtagId,
        apiEndpoint: 'https://api.salento.prd.demodesu.com',
        recaptchaSiteKey: '6Ldtn6kUAAAAAM8_EsDDEMrP9k7wzf8Af_1wHFJY',
        authClientId: '5kvf6ef04lhvql6ntti5t9g14f',
        authWebDomain: 'auth.salento.prd.demodesu.com',
        authUserPoolId: 'ap-northeast-1_zDsh0wGDB',
        webBaseUrl: 'https://salento.prd.demodesu.com',
      };

    case 'http://localhost:8080':
      return {
        stage: 'development',
        dsn,
        gtmId,
        gtagId,
        apiEndpoint: 'https://api.development.salento.prd.demodesu.com',
        recaptchaSiteKey: '6Ldtn6kUAAAAAM8_EsDDEMrP9k7wzf8Af_1wHFJY',
        authClientId: '6g4rm300srbqssb6apa804vp95',
        authWebDomain: 'auth.salento.prd.demodesu.com',
        authUserPoolId: 'ap-northeast-1_DIimd0Wpm',
        webBaseUrl: 'http://localhost:8080',
      };

    default:
      return {
        stage: 'unknown',
        dsn,
        gtmId,
        gtagId,
        apiEndpoint: 'https://api.development.salento.prd.demodesu.com',
        recaptchaSiteKey: '6Ldtn6kUAAAAAM8_EsDDEMrP9k7wzf8Af_1wHFJY',
        authClientId: '6g4rm300srbqssb6apa804vp95',
        authWebDomain: 'auth.salento.prd.demodesu.com',
        authUserPoolId: 'ap-northeast-1_DIimd0Wpm',
        webBaseUrl: 'http://localhost:8080',
      };
  }
};
